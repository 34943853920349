import React from 'react';
import { hideCanvas, rarityCanvas } from '../other/helper';
import { get_all_heroes_details } from '../service';
import { Spinner} from "react-bootstrap";
import Header from './Headerrr';
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import RarityFooter from './RarityFooter';
import { Carousel } from 'react-responsive-carousel';
import Footer from './Footer';


export default class Rarity extends React.Component<{}, { id: number, show: boolean, heroes: any,alertId: string,car:boolean }>  {

    constructor(props: any) {
        super(props);

        this.state = {
            id: 200,
            show: false,
            heroes: {},
            alertId: StatusAlertService.showSuccess('Default success alert!'),
            car:true,
            // showHero:{img:"./1.png",num:0}
        };
        this.getHeroes();
    }

    showSuccessAlert() {
        const alertId = StatusAlertService.showSuccess('Default success alert!');
        this.setState({ alertId });
    }

    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }

    getHeroes() {
        get_all_heroes_details().then((res: any) => {
            //   console.log(res)
            this.setState({ heroes: res.data.result });
            console.log(this.state.heroes);
        })
            .catch((e) => console.log(e))
    }

    handleclick(lid: number) {
        StatusAlertService.showInfo("CyberHeros will be available soon")
        
        return
        // console.log("check", lid);
        //  let temp =lid;
        if (this.state.heroes[lid]) {
            this.setState({ show: true,car:false });
            // window.scrollTo({
            //     top: 0,
            //     behavior: "smooth"
            // });
            StatusAlertService.showSuccess('hero found');
            
        }
        else{
            this.setState({ show: false });
            // window.scrollTo({
            //     top: 0,
            //     behavior: "smooth"
            // });
            StatusAlertService.showError('Hero with id not found');
        }
    }
    render() {
        rarityCanvas()
        return <div style={{ 'margin': 0, 'padding': 0 }}>
            <Header />
            <div className="padding-top-bottom-big background-dark-black-3" style={{ 'paddingTop': 130, 'margin': 0 ,"backgroundColor":"black"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-wrap text-center">
                                <div className="back-title">Rarity Checker</div>
                                <h3 className="text-white">Rarity Checker</h3>
                                <p className="text-white">1500 Cyber Heroes living on the Solana blockchain</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container">
                   
                </div> */}
                <div className="container">
                    <div className="row">

                        <div className='col-md-3'></div>
                        <div className="col-md-6 align-self-center">
                            <h4 className="text-white">Determine the rairty of your Hero</h4>
                            <p className="lead text-grey">Enter your  Heroe's ID number:</p> <div className="suscribe">
                                <input className="form-control text-left" placeholder="Enter ID" type="number" name="mint" onChange={(e) => {
                                    this.setState({ id: parseInt(e.target.value)});

                                }} />
                                
                                <button type="submit" className="btn btn-primary m-0 js-tilt" data-tilt-perspective="300" data-tilt-speed="700"
                                    data-tilt-max="24" onClick={() => {this.handleclick(this.state.id) }}  ><span>Check</span></button></div>

                        </div>
                        
                    </div>
                    {
                        this.state.car && <div className="row">
                        <div className="col-md-12">
                            <div className="title-wrap text-center col-lg-6 mt-5 mt-lg-0 rounded-100" style={{"margin":"auto"}}>
                        
                                <div>
                                <Carousel
                                className='my-3 mx-3'
                                autoPlay={false}
                                centerMode={false}
                                infiniteLoop={false}
                                interval={2000}
                                swipeable = {false}
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}>
                                <div >
                                    <img className='rounded' src="./images/gif.gif" />
                                </div>
                               
                                <div >
                                    <img className='rounded' src="./images/gif.gif" />
                                </div>
                            </Carousel>
                                </div>
                               
                            
                            </div>
                        </div>
                    </div>
                    }


                </div>;
                <div className='container'>
                    
                </div>
                {
                    this.state.show && <div className="container">
                        <div className="row">
                            <div className="col-lg-6 text-center  align-self-center z-bigger">
                                <img style={{ 'maxWidth': 350, 'maxHeight': 380 }} className='rounded' src="./1.png" />

                            </div>
                            <div className="col-lg-6 mt-5 pl=2 mt-lg-0 rounded-500">
                                {/* <a className='btn btn-primary' href="#app" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24"><span>Pre-sale</span></a> */}
                                <h1 className="text-white">CYBER HERO#{this.state.id}</h1>
                                {/* <p className="mt-3 mb-4 pb-3 font-weight-normal text-white">1500 unique Heroes defending over the solana Blochchain</p>

                                <p style={{'display':'inline','color':'yellow'}} className="text-left">Dont think  just Come Let's Mint</p>
                                     */}
                                <br />
                                {/* <h6 style={{'display':'inline','color':'yellow'}}>Want to register in Pre-Sale..!   </h6> */}
                                {/* <a className='btn btn-secondary mt-1'><span>Hero Background Color :Yellow</span></a> */}
                                <a className='btn btn-light mt-1'><span>Hero Background Color :Yellow</span></a>
                                <a className='btn btn-light mt-1'><span>Hero Weapon:IR Blaster</span></a>
                                <a className='btn btn-light mt-1'><span>Hero Energy:Gas</span></a>
                                <p className="text-left text-white mb-0"></p>
                                {/* <p className="text-left text-white mb-0"><small>hhhh</small></p> */}

                            </div>
                        </div>
                    </div>
                }
                {!this.state.show && <><br /><br /><br /><br /><br /><br /><br /></>}
                <StatusAlert/>
            </div>
            <Footer />


        </div>;
    }
}