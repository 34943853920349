let roadmapElements = [
    {
      id: 1,
      title: "Pre-Sale",
      location: "Dragontail, Ascana",
      description:
        "Set up and build our community on Discord and Twitter. Build and launch website. Beta design of comic book cover and NFTS. Release lore and open whitelist.",
      buttonText: "View Frontend Projects",
      date: "",
      icon: "work"
    },
    {
      id: 2,
      title: "READY, SET, GO!",
      location: "Skystead, Craonia",
      description:"Close whitelist, finalize NFT design for the 1st series of 1,500 Cyber Dragons. Finalize pre-sale & mint pricing .88 & .99 SOL & program minting to the Solana blockchain.",
      buttonText: "View Backend Projects",
      date: "",
      icon: "school"
    },
    {
      id: 3,
      title: "50% SOLD",
      location: "South Warren, Geshington",
      description:"Establish community wallet and add in $5k SOL. NFT holders will vote on where the 1st charitable contributions will go and lore direction through polling on our Discord server. Merchandise launch (https://sinalite.com) for NFT holders at 50% of cost. Marketing push to sell out, prepare for secondary market launch. 10 prizes of 1 SOL each will be raffled to the early supporters/OG’s/NFT holders.",
      buttonText: "Company Website",
      date: "",
      icon: "work"
    },
    {
      id: 4,
      title: "100% SOLD",
      location: "South Warren, Geshington",
      description:"Use community wallet to fund charitable donations (10% of royalties) and prizes (20% of royalties). Establish DAO with 5% of mint funds and 50% of royalties for 3 months. Activate perks: hold and sell (for free NFT), buy back & burn (for deflation). Comic book story development begins. Launch on secondary markets. One tree planted for every NFT sold. Lots more prizes of SOL and NFT's.",
      buttonText: "Course Certificate",
      date: "",
      icon: "school"
    },
    {
      id: 5,
      title: "FUTURE PLANS 2022-2023",
      location: "Skystead, Craonia",
      description:"Begin development of battle sims, browser based mini game, short story/novella all based on project lore. Planning for new NFT collection (+15% mint price), with community input: Galaxy Celestials, Cyber Hero Guardians, Cyber Hero Cyborg Leaders and Alien Villains. All subsequent pre-sale availability will be at a discount for current NFT holders, to be minted on the Solana blockchain.",
      buttonText: "College Projects",
      date: "",
      icon: "work"
    }
  ];
  
  export default roadmapElements;
  